import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import {api} from '@/api';
import {ITaskProfile} from '@/interfaces';

export const getters = {
    adminUsers: (state: AdminState) => state.users,
    adminOneUser: (state: AdminState) => (userId: number) => {
        const filteredUsers = state.users.filter((user) => user.id === userId);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },
    adminPatients: (state: AdminState) => state.patients,
    adminOnePatient: (state: AdminState) => (id: number) => {
        const filteredPatient = state.patients.filter((patient) => patient.id === state.patientID);
        if (filteredPatient.length > 0) {
            return { ...filteredPatient[0] };
        }
    },
    adminPatientID: (state: AdminState) => state.patientID,
    adminPatientsWounds: (state: AdminState)  => {
        const filteredPatient = state.patients.filter((patient) => patient.id === state.patientID);
        if (filteredPatient.length > 0) {
            return { ...filteredPatient[0]!.wounds};
        }
    },
    adminPatientWound: (state: AdminState) => state.wound,
    adminWoundTasksWithoutComment: (state: AdminState) => {
        let tasksWithoutComment: ITaskProfile[] = [];
        for(var i = 0; i < state.wound.tasks.length; i++) {
            if(state.wound.tasks[i].type != "COMMENT") {
                tasksWithoutComment.push(state.wound.tasks[i]);
            }
        }
        return tasksWithoutComment;
    },
    adminWoundTasks: (state:AdminState) => state.wound.tasks,
    adminReadTask: (state: AdminState) => (taskID: number) => {
        const task = state.wound.tasks.filter((task) => task.id === taskID);
        if(task.length > 0) {
            return { ...task[0]}
        }
    },
    adminWoundPerspectives: (state: AdminState) => state.wound.perspectives,
    adminTaskMeasurements: (state: AdminState) => (taskID: number) => {
        const task = state.wound.tasks.filter((task) => task.id === taskID);
        if(task.length > 0) {
            return { ...task[0].measurement};
        }

    },
    // adminPerspectivesShots: (state: AdminState) => (persID: number) => {
    //     const perspe = state.wound.perspectives.filter((pers) => pers.id === persID);
    //     if(perspe.length > 0) {
    //         return { ...perspe[0]!.pictures};
    //     }

    // },
    adminVisitDate: (state: AdminState) => {
        return state.visitDate as Date;
    },
    adminMeasurements: (state: AdminState) => {
        return state.measurements;
    },
    adminPictures: (state: AdminState) => {
        return state.pictures;
    },
    adminPicturesURL: (state: AdminState) => {
        return state.picturesSource;
    },
    adminReadComment: (state: AdminState) => state.comment,
    adminReadShowComment: (state: AdminState) => state.showComment,
    adminReadCredentials: (state: AdminState) => state.icd_credentials,
    adminGetQRPatient: (state: AdminState) => state.patient_qr,
    adminGetQRUser: (state: AdminState) => state.user_qr,
    adminGetBReeds: (state: AdminState) => state.breeds,
    adminGetVisits: (state: AdminState) => state.visits,
    adminGetVisitsQuest: (state: AdminState) => state.visitsquest,
    adminGetVisit: (state: AdminState) => state.visit,
    adminGetLanguage: (state: AdminState) => state.language,
    adminGetModePDF: (state: AdminState) => state.modePDF,
    admingGetEditHorse: (state: AdminState) => state.editHorse,
};

const { read } = getStoreAccessors<AdminState, State>('');

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readPatients = read(getters.adminPatients);
export const readPatient = read(getters.adminOnePatient);
export const readPatientID = read(getters.adminPatientID);
export const readPatientWounds = read(getters.adminPatientsWounds);
export const readPatientWound = read(getters.adminPatientWound);
export const readWoundTasks = read(getters.adminWoundTasks);
export const readWoundTasksWithoutComment = read(getters.adminWoundTasksWithoutComment);
export const readWoundPerspectives = read(getters.adminWoundPerspectives);
export const readTaskMeasurements = read(getters.adminTaskMeasurements);
// export const readPerspectivesShots = read(getters.adminPerspectivesShots);
export const readVisitDate = read(getters.adminVisitDate);
export const readMeasurements = read(getters.adminMeasurements);
export const readPictures = read(getters.adminPictures);
export const readPicturesURL = read(getters.adminPicturesURL);
export const readTask = read(getters.adminReadTask);
export const readComment = read(getters.adminReadComment);
export const readShowComment = read(getters.adminReadShowComment);
export const readCredentials = read(getters.adminReadCredentials);
export const readQRUser = read(getters.adminGetQRUser);
export const readQRPatient = read(getters.adminGetQRPatient);
export const readBreeds = read(getters.adminGetBReeds);
export const readVisits = read(getters.adminGetVisits);
export const readVisitsQuest = read(getters.adminGetVisitsQuest);
export const readVisit = read(getters.adminGetVisit);
export const readLanguage = read(getters.adminGetLanguage);
export const readModePDF = read(getters.adminGetModePDF);
export const readEditHorse = read(getters.admingGetEditHorse);