import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';
import {jsPDF} from 'jspdf';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'recover-password',
          name: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'activate/:id',
          name: 'activate',
          component: () => import('./views/ActivateUser.vue'),
        },
        {
          path: 'create-user-email',
          name: 'create-user-email',
          component: () => import('./views/CreateUser.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              name: 'main-dashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'),
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'),
                },
              ],
            },
            {
              path: 'horse',
              component: () => import(/* webpackChunkName: "main-horse" */ './views/main/patient/Patient.vue'),
              redirect: 'horse/horse/all',
              children: [
                {
                  path: 'horse',
                  redirect: 'horse/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-horse-users" */ './views/main/patient/PatientUser.vue'),
                },
                {
                  path: 'horse/create',
                  name: 'main-horse-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-horse-users-create" */ './views/main/patient/CreatePatient.vue'),
                },
                {
                  path: 'horse/overview/:id',
                  name: 'main-horse-overview',
                  component: () => import (
                    './views/main/patient/PatientOverview.vue'),
                },
                {
                  path: 'horse/visit/:id',
                  name: 'main-horse-visit',
                  component: () => import (
                    './views/main/patient/Visit.vue'
                  ),
                },
                {
                  path: 'horse/quest/:id',
                  name: 'main-horse-quest',
                  component: () => import (
                    './views/main/patient/Quest.vue'
                  ),
                },
                {
                  path: 'horse/visitAll/:id',
                  name: 'main-horse-visit-all',
                  component: () => import (
                    './views/main/patient/VisitAll.vue'
                  ),
                },
                {
                  path: 'horse/visit/create',
                  name: 'main-horse-visit-create',
                  component: () => import (
                    './views/main/patient/CreateVisit.vue'
                  ),
                },
              ],
            },
            {
              path: 'wounds',
              component: () => import(/* webpackChunkName: "main-wounds" */ './views/main/wounds/Wound.vue'),
              redirect: 'horse/horse/all',
              children: [
                {
                  path: '/overview/:id',
                  name: 'main-wound-overview',
                  component: () => import(
                    './views/main/wounds/WoundOverview.vue'
                  )
                },
                {
                  path: 'wounds/create',
                  name: 'main-wound-create',
                  component: () => import(
                    './views/main/wounds/WoundCreate.vue'
                  )
                },
                {
                  path: '/visit/:id', //ID of the wound and the visit date
                  name: 'main-wounds-visit',
                  component: () => import(
                    './views/main/wounds/WoundVisit.vue'
                  )
                },
              ]

            },
          ],
        },
      ],
    },
    // {
    //   path: '/*', redirect: '/',
    // },
  ],
});
