//import '@babel/polyfill';
import 'babel-polyfill'; // es6 shim
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import './plugins/vuetify';
import './plugins/vee-validate';
import App from './App.vue';
import router from './router';
import store from '@/store';
import './registerServiceWorker';
import 'vuetify/dist/vuetify.min.css';
import { authHeaders } from '@/api';
import { readToken} from '@/store/main/getters'
import { nextTick } from 'vue/types/umd';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');