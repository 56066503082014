import { IUserProfile, IPatientProfile, IWoundsProfile, IMeasurementProfile, IPicturesProfile, IMeasurementUpdate, ICDs, IVisitProfile, IVisitProfileWithPicsAndMes } from '@/interfaces';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setUsers(state: AdminState, payload: IUserProfile[]) {
        state.users = payload;
    },
    setUser(state: AdminState, payload: IUserProfile) {
        const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
        users.push(payload);
        state.users = users;
    },
    setPatients(state: AdminState, payload: IPatientProfile[]) {
        state.patients = payload;
    },
    setPatientID(state: AdminState, id: number) {
        state.patientID = id;
    },
    setWoundID(state: AdminState, id: number) {
        state.woundID = id;
    },
    setWound(state: AdminState, wound: IWoundsProfile) {
        state.wound = wound;
    },
    setVisitDate(state: AdminState, date: Date) {
        state.visitDate = date;
    },
    setMeasurement(state: AdminState, meas: IMeasurementProfile) {
        state.measurements.push(meas);
    },
    setMeasurementClear(state:AdminState) {
        state.measurements = [];
    },
    changeMeasurement(state: AdminState, mes: IMeasurementUpdate) {
        state.measurements.forEach((element) => {
            if(element.id === mes.id) {
                if(mes.type === 'TEXT') {
                    element.value_char = mes.value_char;
                } else if(mes.type === 'NUMBERSONLY') {
                    element.value_int = mes.value_int;
                } else if(mes.type === 'TRUEFALSE') {
                    element.value_bool = mes.value_bool;
                }
                //Maybe implement the same loop with a break when there is more time 
            }
        });
    },
    deleteMeasurements(state: AdminState, id: number) {
        let indexArray = -1;
        state.measurements.forEach((element, index) => {
            if(element.id === id) {
                indexArray = index;
            }
        });
        state.measurements.splice(indexArray, 1);
        // let index = state.measurements.findIndex((element) => { element.id === id });
        // state.measurements.splice(index, 1);
    },
    deletePicture(state: AdminState, id: number) {
        let indexArray = -1;
        state.pictures.forEach((element, index) => {
            if(element.id === id) {
                indexArray = index;
            }
        });
        state.pictures.splice(indexArray, 1);
    },
    deletePictureURL(state: AdminState, id: number) {
        state.picturesSource.slice(id, 1);
    },
    setPictures(state: AdminState, pic: IPicturesProfile) {
        state.pictures.push(pic);
    },
    setPictureURL(state: AdminState, url: string) {
        state.picturesSource.push(url);
    },
    setPicturesClear(state: AdminState) {
        state.pictures = [];
    },
    setPictureURLClear(state: AdminState) {
        state.picturesSource = [];
    },
    deleteEveryThing(state: AdminState) {
        state.users = [];
        state.patients = [];
    },
    setComment(state: AdminState, comment: IMeasurementProfile) {
        state.comment = comment;
    },
    setCredentials(state: AdminState, icds: ICDs) {
        state.icd_credentials.client_id = icds.client_id;
        state.icd_credentials.client_secret = icds.client_secret;
    },
    setShowComment(state: AdminState, bobo: boolean) {
        state.showComment = bobo;
    },
    setQRPatient(state: AdminState, b64: string) {
        state.patient_qr = b64;
    },
    setQRUser(state: AdminState, b64: string) {
        state.user_qr = b64;
    },
    setVisits(state: AdminState, arr: IVisitProfile[]) {
        state.visits = arr;
    },
    setVisitsQuest(state: AdminState, arr: IVisitProfile[]) {
        state.visitsquest = arr;
    },
    setVisit(state: AdminState, visit: IVisitProfileWithPicsAndMes) {
        state.visit = visit;
    },
    swtichLanguage(state: AdminState) {
        state.language = !state.language;
    },
    setEditHorse(state: AdminState, editHorse: boolean) {
        state.editHorse = editHorse;
    }
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
export const commitSetPatients = commit(mutations.setPatients);
export const commitSetPatientID = commit(mutations.setPatientID);
export const commitSetWoundID = commit(mutations.setWoundID);
export const commitSetWound = commit(mutations.setWound);
export const commitSetVisitDate = commit(mutations.setVisitDate);
export const commitSetMeasurement = commit(mutations.setMeasurement);
export const commitChangeMeasurement = commit(mutations.changeMeasurement);
export const commitMeasurementsClear = commit(mutations.setMeasurementClear);
export const commitSetPicture = commit(mutations.setPictures);
export const commitSetPictureURL = commit(mutations.setPictureURL);
export const commitSetPicturesClear = commit(mutations.setPicturesClear);
export const commitSetPictureURLClear = commit(mutations.setPictureURLClear);
export const commitDeletePicture = commit(mutations.deletePicture);
export const commitDeletePictureURL = commit(mutations.deletePictureURL);
export const commitDeleteMeasurements = commit(mutations.deleteMeasurements);
export const commitDeleteEveryThing = commit(mutations.deleteEveryThing);
export const commitSetComment = commit(mutations.setComment);
export const commitSetCredentials = commit(mutations.setCredentials);
export const commitSetShowComment = commit(mutations.setShowComment);
export const commitSetQRUser = commit(mutations.setQRUser);
export const commitSetQRPatient = commit(mutations.setQRPatient);
export const commitSetVisits = commit(mutations.setVisits);
export const commitSetVisitsQuest = commit(mutations.setVisitsQuest);
export const commitSetVisit = commit(mutations.setVisit);
export const commitSwitchLanguage = commit(mutations.swtichLanguage);
export const commitSetEditHorse = commit(mutations.setEditHorse);