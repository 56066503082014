import { api } from '@/api';
import { ActionContext } from 'vuex';
import { 
    IUserProfileCreate, 
    IUserProfileUpdate,
    IPatientProfileCreate,
    IPatientProfileUpdate
} from '@/interfaces';
import { State } from '../state';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetUsers, commitSetUser, commitSetPatients, commitSetPictureURL, commitSetWound, commitSetCredentials, commitSetQRPatient, commitSetQRUser, commitSetVisits, commitSetVisit, commitSetVisitsQuest} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

type MainContext = ActionContext<AdminState, State>;

export const actions = {
    //Users
    async actionGetUsers(context: MainContext) {
        try {
            const response = await api.getUsers(context.rootState.main.token);
            if (response) {
                commitSetUsers(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateUser(context: MainContext, payload: { id: number, user: IUserProfileUpdate, file: string}) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateUser(context.rootState.main.token, payload.id, payload.user, payload.file),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'User successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateMe(context: MainContext, user: IUserProfileUpdate ) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateMe(context.rootState.main.token, user, "null"),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'User successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateUser(context: MainContext, payload: {user: IUserProfileCreate, file: string}) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createUser(context.rootState.main.token, payload.user, payload.file),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'User successfully created', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    //Patients
    async actionCreatePatient(context: MainContext, payload: {patient: IPatientProfileCreate, file: string, creater_id: number}) {  //The file is an base 64 string
        try {
            const loadingNotification = { content: 'saving patient', showProgress: true};
            commitAddNotification(context, loadingNotification);
            const respone = (await Promise.all([
                api.createPatient(context.rootState.main.token, payload.patient, payload.file, payload.creater_id),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Patient successfully created', color: 'success' });
        } catch(error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPatients(context: MainContext) {
        if(!(context.rootState.main.userProfile?.is_superuser)) {
            this.actionSetPatientsNurse(context);
        }
        else {
            try {
                const response = await api.getPatients(context.rootState.main.token);
                if (response) {
                    commitSetPatients (context, response.data);
                }
            } catch (error) {
                await dispatchCheckApiError(context, error);
            }
        }
    },
    async actionSetPatientsNurse(context: MainContext ) {
        try {   
                const email = context.rootState.main.userProfile?.email;
                if(email != null) {
                    const response = await api.getPatientsNurse(context.rootState.main.token, email);
                    if (response) {
                        commitSetPatients (context, response.data);
                    }
                }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdatePatient(context: MainContext, payload: {id: number, patient: IPatientProfileUpdate, file: string}) {
        try {
            await api.updatePatient(context.rootState.main.token, payload.id, payload.patient, payload.file);
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionSetWound(context: MainContext, id: number) {
        try {
            const response = await api.getWoundByID(context.rootState.main.token, id);
            if(response) {
                commitSetWound(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeletePicture(context: MainContext, id: number) {
        try {
            const response = await api.deleteImage(context.rootState.main.token, id);
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPictureURLs(context:MainContext){
        try {
            let pictures = context.state.pictures;
            for(var i = 0; i < pictures.length; i++) {  
                const response = await api.getImage(context.rootState.main.token, pictures[i].id);
                commitSetPictureURL(context, response.data);
                //
                // let reader = new FileReader();
                // reader.readAsDataURL(response.data);
                // reader.onload = () => {
                //     image = reader.result;
                // }
                // commitSetPictureURL(context, image);
                //Buffer
                // commitSetPictureURL(context, (baseString + Buffer.from(response.data , 'binary').toString('base64')));
                // let reader = new FileReader();
                // reader.readAsBinaryString(response.data);
                // reader.onload = () => {
                //     image = reader.result;
                // }
                // commitSetPictureURL(context, image);
                try {  
                //commitSetPictureURL(context, window.URL.createObjectURL(response.data));
                //commitSetPictureURL(context, response.data);
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetICDCredentials(context: MainContext) {
        try{
            const response = await api.getICDCredentials(context.rootState.main.token);
            commitSetCredentials(context, response.data);
        } catch (error) {
             console.log(error);
        }
    },
    async actionGetQrCodeUser(context: MainContext, id: number) {
        try {
            const respone = await api.getNurseQR(context.rootState.main.token, id);
            commitSetQRUser(context, respone.data);
        } catch (error) {
            console.log(error);
        }
    },
    async actionGetQrCodePatient(context: MainContext, id: number) {
        try {
            const respone = await api.getPatientQR(context.rootState.main.token, id);
            commitSetQRPatient(context, respone.data);
        } catch (error) {
            console.log(error);
        }
    },
    async actionGetVisits(context: MainContext, patient_id: number) {
        try {
            const response = await api.getVisits(context.rootState.main.token, patient_id);
            commitSetVisits(context, response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async actionGetVisitsQuest(context: MainContext, patient_id: number) {
        try {
            const response = await api.getVisitsQuest(context.rootState.main.token, patient_id);
            commitSetVisitsQuest(context, response.data);
        } catch (error) {
            console.log(error);
        }
    },
    async actionGetVisit(context: MainContext, patient_id: number) {
        try {
            const response = await api.getVisit(context.rootState.main.token, patient_id);
            commitSetVisit(context, response.data);
        } catch (error) {
            console.log(error);
        }
    }
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateMe = dispatch(actions.actionUpdateMe);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchCreatePatient = dispatch(actions.actionCreatePatient);
export const dispatchUpdatePatient = dispatch(actions.actionUpdatePatient);
export const dispatchGetPatients = dispatch(actions.actionGetPatients);
export const dispatchGetPatientsNurse = dispatch(actions.actionSetPatientsNurse);
export const dispatchSetWound = dispatch(actions.actionSetWound);
export const dispatchGetPictureURL = dispatch(actions.actionGetPictureURLs);
export const dispatchDeletePicture = dispatch(actions.actionDeletePicture);
export const dispatchGetICDCredentials = dispatch(actions.actionGetICDCredentials);
export const dispatchGetQRUser = dispatch(actions.actionGetQrCodeUser);
export const dispatchGetQRPatient = dispatch(actions.actionGetQrCodePatient);
export const dispatchGetVisits = dispatch(actions.actionGetVisits);
export const dispatchGetVisitsQuest = dispatch(actions.actionGetVisitsQuest);
export const dispatchGetVisit = dispatch(actions.actionGetVisit);
