import axios from 'axios';
import { apiUrl } from '@/env';
import {
  IMeasurementGet, IUserProfile, IUserProfileUpdate, IUserProfileCreate,
   IPatientProfileCreate, IPatientProfile, IUserProfileResponsibleNurses, 
   IWoundCreate, IWoundsProfile, ITaskProfileCreate, IMeasurementProfile, 
   IPerspectiveProfileCreate, IMeasurementUpdate, IPatientProfileUpdate, IWoundUpdate, ICDs, 
   IVisitProfile, IVisitProfileWithPicsAndMes, 
  } from './interfaces';
import {readModePDF} from '@/store/admin/getters';
import internal from 'stream';

export function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
//TODO: When using it for the server change the "server\production\vetdoc\frontend\.env" VUE_APP_DOMAIN_DEV to server address
//      Also change "server\production\vetdoc\frontend\src\env.ts" accordingly
export const api = {

  //Gerneral login activity
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async activateUser(userId: number) {
    return axios.put(`${apiUrl}/api/v1/users/activate/${userId}`)
  },
  //Getting and working with users
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate, file_in: string) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, {
      file: file_in,
      user_in: data
    }, 
    authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate, file_in: string) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, {
      file: file_in,
      user_in: data,
    }, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate, file_in: string) {
    return axios.post(`${apiUrl}/api/v1/users/`, {
      file: file_in,
      user_in: data,
    }, authHeaders(token));
  },
  async createUserEmail(data: IUserProfileCreate, file_in: string) {
    return axios.post(`${apiUrl}/api/v1/users/email`, {
      file: file_in,
      user_in: data
    });
  },
  async deleteUser(token: string, userID: number) {
    return axios.delete(`${apiUrl}/api/v1/users/${userID}`, authHeaders(token));
  }
  ,
  //Working with passwords
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  //Working with patients
  async createPatient(token: string, patient: IPatientProfileCreate, file_in: string, creater_id: number) { //The file is an base 64 string
    return axios.post(`${apiUrl}/api/v1/patients/`, {
      patient_in: patient,
      file: file_in,
      creater_id: creater_id,
    }, authHeaders(token));
  },
  async deletePatient(token: string, patientID: number) {
    return axios.delete(`${apiUrl}/api/v1/patients/${patientID}`, authHeaders(token));
  },
  async updatePatient(token: string, patientID: number, data: IPatientProfileUpdate, file_in: string) {
    return axios.put(`${apiUrl}/api/v1/patients/${patientID}`, {
      patient_in: data,
      file: file_in,
    }, authHeaders(token));
  }
  ,
  async getPatients(token:string) {
    return axios.get<IPatientProfile[]>(`${apiUrl}/api/v1/patients/`, authHeaders(token), );
  },
  async getPatientsNurse(token:string, email:string) {
    return axios.get<IPatientProfile[]>(`${apiUrl}/api/v1/patients/${email}`, authHeaders(token));
  },
  //Getting the list of nurses that are responsible for the patient with the id
  async getNursesForPatients(token:string, id: number) {
    return axios.get<IUserProfileResponsibleNurses[]>(`${apiUrl}/api/v1/users/nurses/${id}`, authHeaders(token))
  },//Getting the list of nurses that are NOT responsible for the patient with the id
  async getNotResponsibleNursesForPatients(token:string, id: number) {
    return axios.get<IUserProfileResponsibleNurses[]>(`${apiUrl}/api/v1/users/not_responsible_nurses/${id}`, authHeaders(token))
  },
  //saving the nurse to the patient
  async saveNurseToPatient(token:string, patientID: number, nurseID: number) {
    return axios.post(`${apiUrl}/api/v1/patients/association`, {
      pid: patientID,
      nid: nurseID,
    }, authHeaders(token));
  },
  //Delete the association 
  async deleteResponsibleNurse(token: string, patientID: number, nurseID: number) {
    return axios.delete(`${apiUrl}/api/v1/patients/association/${patientID}/${nurseID}`, authHeaders(token));
  },
  //Wounds
  async createWound(token:string, newWound: IWoundCreate) {
    return axios.post(`${apiUrl}/api/v1/wounds/`, newWound, authHeaders(token));
  },
  // async getWoundByID(token: string, id: number) {
  //   return axios.get<IWoundsProfile>(`${apiUrl}/api/v1/wounds/concrete/${id}`, authHeaders(token));
  // },
  async getWoundByID(token: string, id: number) {
    return axios.get<IWoundsProfile>(`${apiUrl}/api/v1/wounds/${id}`, authHeaders(token));
  },
  async deleteWound(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/wounds/${id}`, authHeaders(token));
  },
  async updateWound(token: string, id: number, wound: IWoundUpdate) {
    return axios.put(`${apiUrl}/api/v1/wounds/${id}`, wound, authHeaders(token));
  },

  //Tasks
  async createTask(token: string, payload: ITaskProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/tasks/`, payload, authHeaders(token));
  },
  async deleteTask(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/tasks/${id}`, authHeaders(token));
  },
  //Perspectives
  async createPerspective(token: string, payload: IPerspectiveProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/perspectives/`, payload, authHeaders(token));
  },
  async deletePerspective(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/perspectives/${id}`, authHeaders(token));
  },
  async getImage(token:string, id: number) {
    return axios.get<string>(`${apiUrl}/api/v1/pictures/b64/${id}`, {headers: {
      Authorization: `Bearer ${token}`,
      // responseType: 'arraybuffer'
      //responseType: 'blob'
      //responseType: 'image/png'
    },});
  },
  async deleteImage(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/pictures/${id}`, authHeaders(token));
  },
  //Measurements
  async getMeasurementsByTaskID(token: string, taskID: number) {
    return axios.get<IMeasurementGet>(`${apiUrl}/api/v1/tasks/${taskID}`, authHeaders(token));
  },
  async updateMeasurement(token: string, id_mes: number, data: IMeasurementUpdate) {
    return axios.put(`${apiUrl}/api/v1/measurements/${id_mes}`, data, authHeaders(token));
  },
  async deleteMeasurement(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/measurements/${id}`, authHeaders(token));
  },
  async getICDCredentials(token: string) {
    return axios.get<ICDs>(`${apiUrl}/api/v1/utils/icd_credentials`, authHeaders(token));
  }, 
  async getNurseQR(token: string, id: number) {
    return axios.get<string>(`${apiUrl}/api/v1/users/qr_b64/${id}`, authHeaders(token));
  },
  async getPatientQR(token: string, id: number) {
    return axios.get<string>(`${apiUrl}/api/v1/patients/qr_b64/${id}`, authHeaders(token));
  },
  async getProfilePic(token:string, id: number) {
    return axios.get<string>(`${apiUrl}/api/v1/profilepictures/b64/${id}`, {headers: {
      Authorization: `Bearer ${token}`
    },});
  },
  async getProfilePicUser(token:string, id: number) {
    return axios.get<string>(`${apiUrl}/api/v1/profilepicturesuser/b64/${id}`, {headers: {
      Authorization: `Bearer ${token}`
    },});
  },
  async getVisits(token: string, patient_id: number) {
    return axios.get<IVisitProfile[]>(`${apiUrl}/api/v1/patients/visits/${patient_id}`, authHeaders(token));
  },
  async getVisitsQuest(token: string, patient_id: number) {
    return axios.get<IVisitProfile[]>(`${apiUrl}/api/v1/patients/quests/${patient_id}`, authHeaders(token));
  },
  async getVisit(token: string, visit_id: number) {
    return axios.get<IVisitProfileWithPicsAndMes>(`${apiUrl}/api/v1/visit/${visit_id}`, authHeaders(token));
  },
  async getVisitPDF(token: string, visit_id: number, language: string, mode: string) {
    return axios.get<string>(`${apiUrl}/api/v1/visit/pdf/${language}/${mode}/${visit_id}`, authHeaders(token));
  },
  async getQuestPDF(token: string, visit_id: number, language: string) {
    return axios.get<string>(`${apiUrl}/api/v1/visit/quest/${language}/${visit_id}`, authHeaders(token));
  },
  async getVisitPDFAll(token: string, patient_id: number, language: string, mode = "Horizontal") {
    return axios.get<string>(`${apiUrl}/api/v1/visit/pdf/all/${language}/${mode}/${patient_id}`, authHeaders(token));
  }
};
