import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { AdminState } from './state';
import { IMeasurementGet, IMeasurementProfile, IPatientProfile, IPicturesProfile, IWoundsProfile, ICDs, IVisitProfile} from '@/interfaces'

const defaultState: AdminState = {
  users: [], 
  patients: [],
  patientID: -1,
  woundID: -1,
  wounds: [],
  tasks: [],
  perspectives: [],
  wound: {id: -1, name: '---', icd: '---', location: '---', patient: -1, details: '', tasks: [], perspectives: []},
  visitDate: new Date(),
  measurements: new Array<IMeasurementProfile>(),
  pictures: new Array<IPicturesProfile>(),
  picturesSource: new Array<any>(),
  comment: { id: -1, creation_date: new Date(), task: -1},
  icd_credentials: {client_id: ' ', client_secret: ''},
  showComment: false,
  patient_qr: '',
  user_qr: '',
  visit: {id: -1, date: new Date(), patient: -1, measurements: new Array<number>(), pictures: new Array<IPicturesProfile>()},
  visits: new Array<IVisitProfile>(),
  visitsquest: new Array<IVisitProfile>(),
  language: false, //True is englisch, false is german
  editHorse: false,
  modePDF: 'Horizontal',
  breeds: [ "Achal Tekkiner",
  "Altmärkisches Kaltblut",
  "Alt-Württemberger",
  "American Paint Horse (APHA)",
  "American Quarter Horse (AQHA)",
  "American Saddlebred",
  "American Standardbred",
  "Andalusier oder Pura Raza Espanol (PRE)",
  "Anglo-Araber",
  "Appaloosa",
  "Arabisches Partbred",
  "Ardenner",
  "Assateague-Pony",
  "Australian Stock Horse",
  "Australisches Pony",
  "Baschkire",
  "Bayerisches Warmblut",
  "Berber",
  "Bosniake",
  "Brabanter oder Belgisches Kaltblut",
  "Brandenburger Warmblut",
  "Bretone",
  "Budjonny",
  "Caballo de Paso",
  "Camargue-Pferd",
  "Campolina",
  "Clydesdale",
  "Colorado Ranger",
  "Connemara-Pony",
  "Criollo",
  "Dales-Pony",
  "Dartmoor-Pony",
  "Delibos",
  "Deutsches Reitpferd",
  "Deutsches Reitpony",
  "Dülmener",
  "Englisches Vollblut",
  "Exmoor-Pony",
  "Falabella",
  "Fellpony",
  "Fjord-Pferd",
  "Frederiksborger",
  "Freiberger",
  "Friese / Friesenpferd",
  "Gotland-Pony",
  "Hackney",
  "Haflinger",
  "Hannoveraner",
  "Hessisches Warmblut",
  "Highland-Pony",
  "Holsteiner",
  "Huzule",
  "Indianbred",
  "Irisches Vollblut",
  "Irish Draugh Horse und Irish Hunter",
  "Isländer",
  "Kabardiner",
  "Karabagh",
  "Karachai",
  "Kathiawari",
  "Kinsky-Pferd",
  "Kisberer",
  "Kladruber",
  "Knabstrupper",
  "Konik",
  "KWPN",
  "Lettisches Warmblut",
  "Leutstettener oder Sarvar",
  "Lewitzer",
  "Liebenthaler Wildpferd",
  "Lipizzaner",
  "Lusitano",
  "Mangalarga Marchador",
  "Mecklenburger Kaltblut",
  "Mecklenburger Warmblut",
  "Menorquin",
  "Mingrelian",
  "Missouri Fox Trotter",
  "Morgan",
  "Mustang",
  "New Forest-Pony",
  "Nordafrika und arabische Halbinsel",
  "Noriker oder Süddeutsches Kaltblut",
  "Oldenburger",
  "Orlow Traber",
  "Palomino",
  "Paso Fino",
  "Paso Peruano",
  "Percheron",
  "Pinzgauer (Pferd)",
  "Polnisches Warmblut",
  "Przewalski-Pferd",
  "Quarab",
  "Rheinisches Warmblut",
  "Rheinisch-Westfälisches Kaltblut",
  "Rottaler Pferd",
  "Sachsen-Anhaltiner Warmblut",
  "Sächsisches Warmblut",
  "Sächsisch-Thüringisches Kaltblut",
  "Schleswiger Kaltblut",
  "Schwarzwälder Kaltblut",
  "Schweike",
  "schweres Warmblut",
  "Selle Francais oder Normanne",
  "Shagya-Araber",
  "Shetlandpony",
  "Shire",
  "Slowakisches Warmblut",
  "Suffolk",
  "Tarpan",
  "Tennessee Walking Horse",
  "Tersker",
  "Thüringer Warmblut",
  "Tinker oder Gypsy Cob",
  "Tori",
  "Traber",
  "Trakehner",
  "Tschechisches Warmblut",
  "Ukrainer (Pferd)",
  "Vollblutaraber",
  "Warmblut des Zuchtverbandes für deutsche Pferde",
  "Welsh",
  "Westfale",
  "Württemberger Warmblut",
  "Zweibrücker Warmblut"],
};

export const adminModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
